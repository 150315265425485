
.fan-portrait {
  .box_header {
  }

  .check-account {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    width: 170px;
    height: 80px;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    font-size: 20px;
    font-weight: 500;
    line-height: 23px;

    i {
      transform: rotate(90deg);
    }

    span,
    i {
      cursor: pointer;
    }
  }

  .content_box {
    margin-top: 20px;
    min-height: calc(100vh - 232px);
    background-color: #ffffff;
    padding: 24px;
    box-sizing: border-box;

    .content-title {
      font-family: PingFang SC, PingFang SC;
      margin-bottom: 20px;
      font-weight: 500;
      font-size: 24px;
      color: #252632;
      line-height: 28px;
    }

    .no-data {
      margin-top: 194px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-image: none;

      img {
        width: 220px;
        height: 155px;
        object-fit: cover;
      }

      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: rgba(28, 31, 35, 0.35);
        font-family: "PingFang SC, PingFang SC";
      }
    }

    .content-data {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
      overflow: hidden;

      .no-default-data {
        padding: 24px;
        color: rgba(37, 38, 50, 0.6);
        font-size: 16px;
        font-weight: 400;
      }

      .data-item {
        height: 433px;
        box-sizing: border-box;
        background-color: #fafafb;
        border-radius: 4px;
        position: relative;
        // overflow: hidden;

        .item-no-data {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        &.small {
          height: 412px;
        }

        &.full-columns {
          grid-column: 1 / -1;
        }

        .item-title {
          position: absolute;
          left: 20px;
          top: 20px;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #161823;
          z-index: 10;
        }

        .chart {
          width: 100%;
          height: 100%;
          padding-top: 20px;
          box-sizing: border-box;
          position: absolute;
          // top: 50%;
          // left: 50%;
          // transform: translate(-50%, -50%);
          display: flex;
          justify-content: center;
          align-items: center;
          // background-color: black;

          &-child {
            width: 50%;
            height: 100%;
            position: relative;
            // background: black;

            // &:nth-child(2) {
            //   // background: pink;
            // }
          }

          .label-notation {
            position: absolute;
            display: flex;
            gap: 18px;
            left: 50%;
            top: 40px;
            transform: translateX(42px);

            span {
              font-family: PingFang SC, PingFang SC;
              font-weight: 500;
              font-size: 14px;
              color: #000000;
              line-height: 16px;
              text-align: left;
              font-style: normal;
              text-transform: none;
            }
          }

          &.padding-inline-20 {
            padding-inline: 20px;

            ::v-deep .el-table {
              margin-top: 40px;
            }

            ::v-deep .el-table th.el-table__cell {
              background-color: #efeff0;
            }

            ::v-deep .el-table td.el-table__cell,
            .el-table th.el-table__cell.is-leaf {
              border: none;
            }

            ::v-deep .el-table--scrollable-y .el-table__body-wrapper::-webkit-scrollbar {
              width: 4px;
              /* 滚动条的宽度 */
            }

            ::v-deep .el-table--scrollable-y .el-table__body-wrapper::-webkit-scrollbar-thumb {
              background-color: #efeff0;
              /* 滚动条的颜色 */
              border-radius: 20px;
              /* 滚动条的圆角半径 */
            }

            ::v-deep .el-table--scrollable-y .el-table__body-wrapper::-webkit-scrollbar-thumb:hover {
              background-color: #efeff0;
              /* 滚动条的悬停颜色 */
            }

            ::v-deep .el-table--scrollable-y .el-table__body-wrapper::-webkit-scrollbar-track {
              background-color: #fff;
              /* 滚动条的轨道颜色 */
              border-radius: 20px;
              /* 滚动条轨道的圆角半径 */
            }
          }
        }
      }
    }
  }
}
